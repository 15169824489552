import React, { useState } from 'react';
import { Container, Box, Typography, Button } from "@mui/material";
import CustomAppBar from "../CustomAppBar";
import KingdomTabs from "../KingdomTabs";
import FeywildWorldData from "./FeywildWorldData";
import StartButton from "./StartButton";
import "./LandingPage.css"

export default function FeywildLandingPage() {
    const [showInfo, setShowInfo] = useState(false);

    const handleButtonClick = () => {
        setShowInfo(true);
    };

    return (
        <Box className="background-falling-star-garden" sx={!showInfo ? { display: 'flex', flexDirection: 'column', minHeight: '100vh' } : {}}>
            {showInfo ? <CustomAppBar /> : <></>}
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: '1rem' }}>
                {!showInfo ? (
                    <>
                        <Box sx={{ backdropFilter: 'blur(2px)', padding: '2rem', margin: '1rem',textAlign: 'center', backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '10px' }}>
                            <Typography variant="h4" sx={{ fontSize: '2rem', color: 'black' }}>
                                Merry Christmas! Enter a world built just for you
                            </Typography>
                        </Box>
                        <Button
                            onClick={handleButtonClick}
                            sx={{
                                backgroundColor: 'white',
                                color: 'black',
                                animation: 'jump 1s infinite',
                                '&:hover': {
                                    backgroundColor: 'pink',
                                },
                                margin: 'auto',
                                display: 'block',
                                fontSize: '1.5rem',
                                padding: '1rem 2rem',
                            }}
                        >
                            Enter the Feywild
                        </Button>
                    </>
                ) : (
                    <Container className="content" sx={{ backdropFilter: 'blur(2px)', marginBottom: '1rem', backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '10px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 2 }}>
                            <FeywildWorldData />
                            {/* <Typography variant="h3" sx={{ fontSize: '2rem' }}>Notable Characters</Typography> */}
                            {/* <KingdomTabs />  */}
                            {/* Add the notable characters tabs */}
                            {/* <StartButton /> */}
                            {/* Create a start button inviting to the festival of the eclipse */}
                        </Box>
                    </Container>
                )}
            </Box>
        </Box>
    )
}