import { Typography } from "@mui/material";


export default function WorldData() {
    const intro = "The Feywild is a realm of unparalleled beauty and magic, a place where the laws of nature intertwine with the forces of arcana. Here, the land itself seems alive, responding to the moods and desires of its denizens. Towering forests glitter with bioluminescent flora, crystalline rivers hum with melodies, and the skies often dance with hues unknown to mortal eyes. At the heart of this enchanting world lies the Seelie Court, ruled by the radiant Queen Titania. The Seelie Court presides over the Feywild with a benevolent but firm hand, ensuring the realm remains vibrant and harmonious. However, the Feywild was not always as it is today."
    const backstory = "Long ago, the Feywild was home to four great courts, each tied to a season. While the Summer and Spring Courts thrived in their warmth and growth, the Winter and Autumn Courts held sway over the cycles of rest and decay. The balance they maintained ensured the Feywild’s eternal beauty and magic.But the Unseelie Fey, champions of the Winter Court, sought to expand their influence. Their ambitions led to an era of conflict with the Seelie Fey of the Summer Court. This Feywild war reshaped the realm, culminating in the devastating fall of the Winter Court. Its lands became desolate, its magic diminished, and its denizens scattered. Now, under Queen Titania’s rule, the Summer Court’s brilliance dominates the Feywild, with the remnants of the Unseelie and Winter Courts all but forgotten."
    const festival = "As the Feywild flourishes, the Seelie Court celebrates its beauty and magic with grand festivals. One such occasion, the Festival of Falling Stars, approaches. This annual event marks the peak of the magical meteor shower that graces the Feywild skies. During the festival, shooting stars light up the heavens, casting their power over the land and igniting the Feywild in a cascade of ethereal light. This year’s festival promises to be especially remarkable, as the stars are said to shine brighter than ever and the arane ceremony will be performed not only by Queen Titania, but also a variety of honored guests. The Queen herself has invited many to attend the festivities held in the renowned Falling Star Gardens—a sacred place of vibrant flowers, glowing constellations, and ancient mysteries. It is a time of revelry, wonder, and magical renewal, where fey and mortals alike gather to marvel at the heavens and partake in the Feywild’s boundless joy."
    const hook = "The Festival of Falling Stars is not just a celebration but also a reminder of the Feywild’s enduring magic and the Seelie Court’s unyielding guardianship. As an honored guest, you will step into this vibrant world, explore its wonders, and partake in the festivities—a journey that may lead you deeper into the Feywild’s mysteries than you could ever imagine. Prepare to embark on a tale of magic, beauty, and destiny as you enter the Feywild and take your place in its unfolding story."
    return (
        <>
            <Typography variant="h3">The Feywild: A Vibrant Realm of Magic</Typography>
            {[intro, backstory, festival, hook].map((text, index) => (
                <Typography key={index} variant="body1" sx={{ whiteSpace: "pre-wrap", textAlign: 'left' }}>
                    {text}
                </Typography>
            ))}
            <Typography variant="h5" sx={{ fontWeight: 'bold', whiteSpace: "pre-wrap", textAlign: 'left' }}>
                {"Your Task: Design a character to participate in the Festival of Falling Stars ceremony"}
            </Typography>
        </>
    )

}